import React from 'react'
import Layout from '../components/layout'

const Datenschutz = () => {
	return (
		<Layout title="Datenschutz">
			<div className="container-fluid pl-4">
				<div className="my-row mt-5">
					<h2>
          Datenschutzerklärung
					</h2>
				</div>
				<div className="my-row mt-4">
					<h3>
          1. Datenschutz bei betterHR
					</h3>
				</div>
				<div className="my-row">
					<p>
          Das Thema Datenschutz hat für uns, die betterHR GmbH, herausragende Bedeutung.
					</p>
					<p className="mt-2">
          Als Unternehmen im Bereich Personalberatung ist es für uns und unsere Kunden essentiell, dass im Rahmen unserer Tätigkeit die Regelungen der Datenschutz-Grundverordnung (DS-GVO) sowie des jeweiligen nationalen Datenschutzrechtes eingehalten werden.
					</p>
					<p className="mt-2">
          Mit dieser Datenschutzerklärung wollen wir Sie – unsere Kunden – und die Öffentlichkeit beim Besuch und der Nutzung unserer Webseite und Dienstleistungen darüber aufklären, auf welchen Rechtsgrundlagen und auf welche Art, in welchem Umfang und zu welchem Zweck wir als Verantwortliche personenbezogene Daten verarbeiten und welche Rechte Ihnen als betroffenen Personen zustehen.
					</p>
				</div>
				<div className="my-row mt-4">
					<h3>
          2. Kontaktdaten des Verantwortlichen
					</h3>
				</div>
				<div className="my-row">
					<p>
          Verantwortlicher im Sinne der Art. 4 Ziffer 7 der DS-GVO und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten ist die:
					</p>
				</div>
				<div className="my-row">
					<p className="mt-2 font-weight-normal">
          betterHR GmbH<br/>
          Helmholtzstraße 2-9<br/>
          DE-10587 Berlin<br/>
          E-Mail: info@betterhr.de<br/>
          Webseite: betterhr.de
					</p>
				</div>
				<div className="my-row mt-4">
					<h3>
          3. Umfang der Verarbeitung personenbezogener und weiterer Daten
					</h3>
				</div>
				<div className="my-row">
					<p>
          Wir erheben und verwenden Ihre personenbezogenen Daten grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Dienstleistungen erforderlich ist.
					</p>
					<p className="mt-2">
          Personenbezogene Daten sind solche Daten, die auf Sie als unseren Kunden oder Dritte persönlich beziehbar sind, wie etwa Name, E-Mail, Adresse, Nutzerverhalten, IP-Adresse.
					</p>
					<p className="mt-2">
          Die Verarbeitung personenbezogener Daten erfolgt ausschließlich auf gesetzlicher Grundlage und – sofern für die konkrete Verarbeitung keine solche besteht – mit Ihrer Einwilligung als betroffener Person.
					</p>
					<p className="mt-2">
          Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister (Auftragsverarbeiter, Art. 4 Ziff. 8, Art. 28 DS-GVO). Diese wurden von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig kontrolliert.
					</p>
				</div>
				<div className="my-row mt-4">
					<h3>
          a) Umfang der Verarbeitung personenbezogener Daten beim informatorischen Besuch unserer Webseite
					</h3>
				</div>
				<div className="my-row">
					<p>
          Bei einer bloß informatorischen Nutzung unserer Webseiten erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wir erheben folgende Daten, die für uns technisch erforderlich sind, um Ihnen als (potentiellen) Kunden unsere Webseite anzuzeigen und deren Stabilität und Sicherheit zu gewährleisten:
					</p>
					<ul className="text-dark mt-2">
						<li>
            die IP-Adresse;
						</li>
						<li>
            Datum und Uhrzeit Ihrer Anfrage;
						</li>
						<li>
            Zugriffsstatus/HTTP-Statuscode;
						</li>
						<li>
            jeweils übertragene Datenmenge;
						</li>
						<li>
            Webseite, von der die Anforderung kommt;
						</li>
						<li>
            Ihr verwendeter Browser;
						</li>
						<li>
            Ihr Betriebssystem;
						</li>
						<li>
            Sprache und Version Browsersoftware;
						</li>
						<li>
            die Referrer-URL (die zuvor von Ihnen besuchte Website);
						</li>
						<li>
            der anfragende Provider;
						</li>
					</ul>
				</div>
				<div className="my-row">
					<p>
          Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht statt.
					</p>
					<p className="mt-2">
          Die Rechtsgrundlage vorstehender Verarbeitung ist: Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Unser berechtigtes Interesse erfolgt aus den oben aufgelisteten Zwecken der Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
					</p>
				</div>
				<div className="my-row mt-4">
					<h3>
          b) Umfang der Verarbeitung personenbezogener Daten bei der Kontaktaufnahme mit uns
					</h3>
				</div>
				<div className="my-row">
					<p>
          Im Falle der Kontaktaufnahme über die auf unserer Webseite angegebene E-Mail-Adresse werden Ihre E-Mail-Adresse sowie die von Ihnen weiter übermittelten personenbezogenen Daten gespeichert und zur Beantwortung Ihrer Anfrage verarbeitet.
					</p>
					<p className="mt-2">
          Die Rechtsgrundlage vorstehender Verarbeitung ist Art. 6 Abs. 1 S.1 lit. f DSGVO. Unser berechtigtes Interesse erfolgt aus der Bearbeitung der uns von Ihnen übersandten Anfrage bzw. Nachricht.
					</p>
					<p className="mt-2">
          Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht statt.
					</p>
				</div>
				<div className="my-row mt-4">
					<h3>
          c) Umfang der Verarbeitung bei Anmeldung zu unserem Newsletter
					</h3>
				</div>
				<div className="my-row">
					<p>
          Im Falle der Anmeldung zu unserem Newsletter, mit dem wir Sie über unsere aktuellen Angebote oder interessante Neuigkeiten informieren, erheben wir folgende personenbezogenen Daten:
					</p>
					<ul className="text-dark mt-2">
						<li>
            Ihren Vor- und Nachnamen;
						</li>
						<li>
            Ihre E-Mail-Adresse;
						</li>
						<li>
            Ihre IP-Adresse;
						</li>
						<li>
            das Datum und die Uhrzeit Ihrer Anfrage;
						</li>
					</ul>
					<p>
          Wir verwenden Ihre E-Mail-Adresse und freiwillige Angaben zu Ihrer Person dafür, Ihnen regelmäßig unseren Newsletter zu übersenden. Für den Empfang unseres Newsletters ist die Angabe einer E-Mail- Adresse ausreichend. Die zusätzlichen freiwilligen Angaben zu Ihrer Person dienen lediglich dazu, den Newsletter zu personalisieren.
					</p>
					<p className="mt-2">
          Für die Anmeldung zu unserem Newsletter verwenden wir das sogenannte Double-opt-in-Verfahren. Das heißt, dass wir Ihnen nach Ihrer Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in welcher wir Sie um Bestätigung bitten, dass Sie den Versand des Newsletters wünschen. Wenn Sie Ihre Anmeldung nicht innerhalb von 24 Stunden bestätigen, werden Ihre Informationen gesperrt und nach einem Monat automatisch gelöscht. Darüber hinaus speichern wir jeweils Ihre eingesetzten IP-Adressen und Zeitpunkte der Anmeldung und Bestätigung. Zweck des Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen möglichen Missbrauch Ihrer persönlichen Daten aufklären zu können. Nach Ihrer Bestätigung speichern wir Ihre E-Mail-Adresse zum Zweck der Zusendung des Newsletters.
					</p>
					<p className="mt-2">
          Rechtsgrundlage für den Versand unseres Newsletters und die mit ihm verbundene Erfolgsmessung ist Art. 6 Abs. 1 lit. a, Art. 7 DS-GVO i.V.m § 7 Abs. 2 Nr. 3 UWG bzw. – für unsere bestehenden Kunden – die gesetzliche Erlaubnis gem. § 7 Abs. 3 UWG.
					</p>
					<p className="mt-2">
          Die Rechtsgrundlage für die Protokollierung der Anmeldung ist Art. 6 Abs. 1 lit. f DS-GVO. Unser berechtigtes Interesse folgt aus dem vorgenannten Zweck als auch aus dem Aspekt der Zurverfügungstellung eines nutzerfreundlichen und sicheren Newsletter-Systems, das sowohl unseren geschäftlichen Interessen dient, als auch Ihren Erwartungen entspricht und den Nachweis Ihrer Einwilligung dokumentiert.
					</p>
					<p className="mt-2">
          Ihre Einwilligung in die Übersendung des Newsletters können Sie jederzeit widerrufen und den Newsletter abbestellen. Den Widerruf können Sie durch Klick auf den in jeder Newsletter-E-Mail bereitgestellten Link per E-Mail an <a href="mailto:unsubscribe@betterhr.de">unsubscribe@betterhr.de</a> oder durch eine Nachricht an die in Ziffer 6 der Datenschutzerklärung genannte E-Mail Adresse erklären.
					</p>
					<p className="mt-2">
          Der Versand der Newsletter erfolgt mittels des Versanddienstleisters MailChimp, eines Dienstleisters im Bereich Newsletter-Versand namens Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA.
					</p>
					<p className="mt-2">
          Die Datenschutzbestimmungen des Versanddienstleisters können Sie hier einsehen: <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>.
					</p>
					<p className="mt-2">
          Die Rocket Science Group LLC ist zertifiziertes Mitglied des EU-US Privacy-Shield-Abkommens <a href="https://www.privacyshield.gov">(https://www.privacyshield.gov)</a>.
					</p>
					<p className="mt-2">
          Rechtsgrundlage für den Einsatz von MailChimp sind unsere berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DS-GVO. Die Rocket Science Group LLC wird aufgrund eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DS-GVO für uns tätig.
					</p>
					<p className="mt-2">
          Unsere Newsletter enthalten einen sog. „web-beacon“, d.h. eine pixelgroße Datei, die vom Server unseres Versanddienstleisters abgerufen wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und Zeitpunkt des Abrufs erhoben.
					</p>
					<p className="mt-2">
          Diese Informationen werden zur technischen Verbesserung der Services anhand der technischen Daten oder der Zielgruppen und ihres Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Zu den statistischen Erhebungen gehört ebenfalls die Feststellung, ob die Newsletter geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen können aus technischen Gründen zwar den einzelnen Newsletter- Empfängern zugeordnet werden. Es ist jedoch weder unser Bestreben, noch, sofern eingesetzt, das des Versanddienstleisters, einzelne Nutzer zu beobachten. Die Auswertungen dienen uns viel mehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte auf sie anzupassen oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden.
					</p>
					<p className="mt-2">
          Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss die Einwilligung zum Newsletter-Empfang als solche widerrufen werden.
					</p>
				</div>
				<div className="my-row mt-4">
					<h3>
          d) Nutzung der Chat-Software LiveZilla
					</h3>
				</div>
				<div className="my-row">
					<p>
          Wir nutzen für unsere Webseiten die Live-Chat Software LiveZilla der LiveZilla GmbH, Byk-Gulden-Straße 18, 78224 Singen. LiveZilla ist eine Live-Chat-Tool, welches eine unmittelbare Kommunikation zwischen uns und Ihnen als Besucher unserer Webseiten ermöglicht.
					</p>
					<p>
          Bei jedem einzelnen Abruf unserer Webseiten, die mit dem LiveZilla-Chat-Tool ausgestattet ist, werden durch diese Komponente Daten mit dem Zweck gesammelt, das Live-Chat-Tool zu betreiben und den Betrieb des Systems zu analysieren. Wir erheben hierzu folgende Daten:
					</p>
					<ul className="text-dark mt-2">
						<li>
            die IP-Adresse (anonymisiert);
						</li>
						<li>
            Ihren verwendeten Browser;
						</li>
						<li>
            Ihr Betriebssystem;
						</li>
						<li>
            die Sprache und Version Ihrer Browsersoftware;
						</li>
					</ul>
					<p>
          Soweit Sie über das LiveZilla-Chat-Tool selbst personenbezogene Daten, wie etwa Ihre E-Mail-Adresse oder Ihren Namen, eingeben, werden diese personenbezogenen Daten von uns verarbeitet, um Ihre Anfrage zu bearbeiten und Ihnen gegebenenfalls Muster-Dokumente oder E-Books im Zusammenhang mit Ihrer Anfrage zur Verfügung zu stellen.
					</p>
					<p className="mt-2">
          Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen findet nicht statt.
					</p>
					<p className="mt-2">
          Die Rechtsgrundlage vorstehender Verarbeitung ist Art. 6 Abs. 1 S.1 lit. f DSGVO. Unser berechtigtes Interesse erfolgt aus der Bearbeitung der uns von Ihnen über das LiveZilla-Chat-Tool übersandten Anfrage sowie um unser Angebot fortwährend auszuweiten und zu verbessern.
					</p>
				</div>
				<div className="my-row mt-4">
					<h3>e) Webanalysedienste / Trackingdienste</h3>
				</div>
				<div className="my-row">
					<p>aa) Soweit Sie Ihre Einwilligung erklärt haben, wird zur bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Webseiten Google Analytics, ein Webanalysedienst der Google Ireland Ltd, Gordon House, Barrow Street, Dublin 4. Irland („Google“) genutzt.  </p>
					<p className="mt-2">Die Nutzung umfasst die Betriebsart „Universal Analytics“. Hierdurch ist es möglich, Daten, Sitzungen und Interaktionen über mehrere Geräte hinweg einer pseudonymen User-ID zuzuordnen und so die Aktivitäten eines Nutzers geräteübergreifend zu analysieren.  </p>
					<p className="mt-2">Mit dieser Technologie ist es uns möglich, unser Webangebot zu erhalten und zu erweitern. Mittels Reports können wir unser Webangebot bemessen, auswerten und unsere Inhalte optimieren. Zum Schutze unserer Nutzer und Partner können wir auch Betrugs- und Sicherheitsrisiken erkennen sowie abwehren.  </p>
					<p className="mt-2">Google verarbeitet Ihre Daten in unserem Auftrag durch den Einsatz von sogenannten „Cookies“. Dies sind Textdateien, die auf Ihrem Endgerät gespeichert werden und uns eine Analyse der Benutzung unseres Webangebotes ermöglichen.  </p>
					<p className="mt-2">Der Schutz Ihrer Daten ist uns wichtig, deshalb haben wir Google Analytics zusätzlich um den Konfigurationsparameter „anonymizeIp“ erweitert. Ihre IP-Adresse wird durch den Code ausschließlich gekürzt erfasst. Wir verarbeiten Ihre personenbezogenen Nutzungsdaten daher anonymisiert. Ein Rückschluss auf Ihre Person ist uns nicht möglich.  </p>
					<p className="mt-2">Wie Google Ihre Daten bei dem Besuch von Webseiten verarbeitet, können Sie <a href="https://policies.google.com/technologies/partner-sites?hl=de">hier</a> abrufen.  </p>
					<p className="mt-2">Sofern Sie eine anonymisierte Analyse Ihrer Webseitennutzung durch Google Analytics nicht wünschen, können Sie jederzeit mit Wirkung für die Zukunft widersprechen.  </p>
					<p className="mt-2">Ein Unterbinden des Trackings durch Google Analytics bietet Ihnen folgendes Browser-Plugin, welches sie unter folgendem Link herunterladen und installieren können: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>  </p>
					<p className="mt-2">Die Speicherung der Cookies können Sie ebenfalls in den entsprechenden Einstellungen Ihrer Browser-Software verhindern.  </p>
					<p className="mt-2">Weitere Informationen zu den Nutzungsbedingungen von Google Analytics und den datenschutzrechtlichen Regelungen finden Sie unter: <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://www.google.de/intl/de/policies/">https://www.google.de/intl/de/policies/</a>  </p>
					<p className="mt-2">bb) Sofern Sie eingewilligt haben, wird zur bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Webseite das sog. &quot;Facebook-Pixel&quot; des sozialen Netzwerkes Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, eingesetzt.  </p>
					<p className="mt-2">Mit Hilfe des Facebook-Pixels ist es Facebook möglich, die Besucher unserer Webseite als Zielgruppe für die Darstellung von Anzeigen (sog. &quot;Facebook-Ads&quot;) zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben, oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. „Custom Audiences“).  </p>
					<p className="mt-2">Mit Hilfe des Facebook-Pixels möchten wir zudem sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen, ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Webseite weitergeleitet wurden (sog. „Conversion“).  </p>
					<p className="mt-2">Das Facebook-Pixel wird beim Aufruf unserer Webseite unmittelbar durch Facebook eingebunden und kann auf Ihrem Endgerät ein sog. Cookie speichern. Wenn Sie sich anschließend bei Facebook einloggen oder im eingeloggten Zustand Facebook besuchen, wird der Besuch unseres Onlineangebotes in Ihrem Profil vermerkt. Die über Sie erhobenen Daten sind für uns anonym, bieten uns also keine Rückschlüsse auf die Identität der Nutzer.  </p>
					<p className="mt-2">Allerdings werden die Daten von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist und von Facebook sowie zu eigenen Marktforschungs- und Werbezwecken verwendet werden kann. Sofern wir Daten zu Abgleichzwecken an Facebook übermitteln sollten, werden diese lokal im Browser verschlüsselt und erst dann an Facebook über eine gesicherte https-Verbindung gesendet.  </p>
					<p className="mt-2">Dies erfolgt alleine mit dem Zweck, einen Abgleich mit den gleichermaßen durch Facebook verschlüsselten Daten herzustellen.  </p>
					<p className="mt-2">Wir nutzen beim Einsatz des Facebook-Pixels die Zusatzfunktion „erweiterter Abgleich“. Hierbei werden Daten zur Bildung von Zielgruppen („Custom Audiences“ oder „Look Alike Audiences“) verschlüsselt an Facebook übermittelt.  </p>
					<p className="mt-2">Nähere Informationen zu Möglichkeiten zum Schutz Ihrer Privatsphäre bei Facebook finden Sie in den Datenschutzhinweisen von Facebook unter <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.  </p>
					<p className="mt-2">Speziell, welche Inhalte und Informationen Sie durch Ihre Nutzung von Facebook geteilt haben, können Sie über verschiedene Facebook-Tools / Funktionen einsehen. Weitere Informationen hierzu finden Sie unter <a href="https://de-de.facebook.com/privacy/explanation">https://de-de.facebook.com/privacy/explanation</a>  </p>
				</div>
				<div className="my-row mt-4">
					<h3>f) Werbung auf unserer Webseite</h3>
				</div>
				<div className="my-row">
					<p className="mt-2">aa) Wir haben auf dieser Website Google Ads integriert. Google Ads ist ein Dienst zur Internetwerbung, der es Werbetreibenden gestattet, sowohl Anzeigen in den Suchmaschinenergebnissen von Google als auch im Google-Werbenetzwerk zu schalten. Google Ads ermöglicht es einem Werbetreibenden, vorab bestimmte Schlüsselwörter festzulegen, mittels derer eine Anzeige in den Suchmaschinenergebnissen von Google ausschließlich dann angezeigt wird, wenn der Nutzer mit der Suchmaschine ein schlüsselwortrelevantes Suchergebnis abruft. Im Google-Werbenetzwerk werden die Anzeigen mittels eines automatischen Algorithmus und unter Beachtung der zuvor festgelegten Schlüsselwörter auf themenrelevanten Websites verteilt.  </p>
					<p className="mt-2">Betreibergesellschaft der Dienste von Google Ads ist Google. Der Zweck von Google Ad ist, die Bewerbung unserer Website durch die Einblendung von interessenrelevanter Werbung auf den Websites von Drittunternehmen und in den Suchmaschinenergebnissen der Suchmaschine Google und eine Einblendung von Fremdwerbung auf unserer Website. Gelangt eine betroffene Person über eine Google- Anzeige auf unsere Website, wird auf dem informationstechnologischen System der betroffenen Person durch Google ein sogenannter Conversion-Cookie abgelegt. Was Cookies sind, wurde oben bereits erläutert. Ein Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und dient nicht zur Identifikation der betroffenen Person. Über den Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der Warenkorb von einem Online-Shop-System, auf unserer Website aufgerufen wurden. Durch den Conversion-Cookie können sowohl wir als auch Google nachvollziehen, ob eine betroffene Person, die über eine AdWords-Anzeige auf unsere Website gelangt ist, einen Umsatz generierte, also einen Warenkauf vollzogen oder abgebrochen hat.  </p>
					<p className="mt-2">Die durch die Nutzung des Conversion-Cookies erhobenen Daten und Informationen werden von Google verwendet, um Besuchsstatistiken für unsere Website zu erstellen. Diese Besuchsstatistiken werden durch uns wiederum genutzt, um die Gesamtanzahl der Nutzer zu ermitteln, welche über Google Ads- Anzeigen an uns vermittelt wurden, also um den Erfolg oder Misserfolg der jeweiligen Google Ads- Anzeige zu ermitteln und um unsere Google Ads-Anzeigen für die Zukunft zu optimieren. Weder unser Unternehmen noch andere Werbekunden von Google-Ads erhalten Informationen von Google, mittels derer die betroffene Person identifiziert werden könnte.  </p>
					<p className="mt-2">Mittels des Conversion-Cookies werden personenbezogene Informationen, beispielsweise die durch die betroffene Person besuchten Websites, gespeichert. Bei jedem Besuch unserer Websites werden demnach personenbezogene Daten, einschließlich der IP-Adresse des von der betroffenen Person genutzten Internetanschlusses, an Google in den Vereinigten Staaten von Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den Vereinigten Staaten von Amerika gespeichert. Google gibt diese über das technische Verfahren erhobenen personenbezogenen Daten unter Umständen an Dritte weiter.  </p>
					<p className="mt-2">Die betroffene Person kann die Setzung von Cookies durch unsere Website jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung des genutzten Internetbrowsers würde auch verhindern, dass Google einen Conversion-Cookie auf dem informationstechnologischen System der betroffenen Person setzt. Zudem kann ein von Google Ads bereits gesetzter Cookie jederzeit über den Internetbrowser oder andere Softwareprogramme gelöscht werden.  </p>
					<p className="mt-2">Ferner besteht für die betroffene Person die Möglichkeit, der interessenbezogenen Werbung durch Google zu widersprechen. Hierzu muss die betroffene Person von jedem der von ihr genutzten Internetbrowser aus den Link <a href="https://www.google.de/settings/ads">https://www.google.de/settings/ads</a> aufrufen und dort die gewünschten Einstellungen vornehmen.  </p>
					<p className="mt-2">Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a> abgerufen werden.  </p>
					<p className="mt-2">Rechtsgrundlage für den Einsatz von Google Ads ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Unser berechtigtes Interesse besteht in der Vermarktung unserer Angebote  </p>
					<p className="mt-2">bb) Unsere Webseite benutzt zudem den Google Tag Manager. Google Tag Manager ist eine Lösung, mit der Vermarkter Website-Tags über eine Oberfläche verwalten können. Das Tool Tag Manager selbst (das die Tags implementiert) ist eine Cookie-lose Domain und erfasst keine personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.  </p>
				</div>
				<div className="my-row mt-4">
					<h3>h) Einsatz von Cookies</h3>
				</div>
				<div className="my-row">
					<p>Unsere Webseite verwendet neben den bereits vorstehend beschriebenen Cookies weitere Cookies. Bei Cookies handelt es sich generell um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.  </p>
					<p className="mt-2">Sie haben die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.  </p>
				</div>
				<h2>4. Onlinepräsenzen in sozialen Medien</h2>
				<p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen informieren zu können. Hierbei handelt es sich um folgende soziale Netzwerke und Plattformen:  </p>
				<ul className="text-dark mt-2">
					<li>Facebook;  </li>
					<li>LinkedIn;  </li>
					<li>XING;  </li>
				</ul>
				<p className="mt-2">Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.  </p>
				<p className="mt-2">Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wird die Daten der Nutzer sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.  </p>
				<div className="my-row mt-4">
					<h2>5. Datenlöschung und Speicherdauer</h2>
				</div>
				<div className="my-row">
					<p>Die personenbezogenen Daten der betroffenen Person werden generell gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt.  </p>
					<p className="mt-2">Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.  </p>
					<p className="mt-2">Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.  </p>
				</div>
				<div className="my-row mt-4">
					<h2>6. Ihre Rechte</h2>
				</div>
				<div className="my-row">
					<p>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden Daten:</p>
				</div>
				<div className="my-row">
					<ul className="text-dark mt-2">
						<li>Recht auf Auskunft;  </li>
						<li>Recht auf Berichtigung oder Löschung;  </li>
						<li>Recht auf Einschränkung der Verarbeitung;  </li>
						<li>Recht auf Widerruf Ihrer Einwilligung;  </li>
						<li>Recht auf Widerspruch gegen die Verarbeitung; – Recht auf Datenübertragbarkeit;  </li>
					</ul>
				</div>
				<div className="my-row">
					<p className="mt-2">Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.  </p>
					<p className="mt-2">Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen (Art. 6 Abs. 1 lit. f DS-GVO), können Sie Widerspruch gegen die Verarbeitung einlegen. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.  </p>
					<p className="mt-2">Sie können sämtliche der vorgenannten Rechte geltend machen, indem Sie sich unter <a href="mailto:datenschutz@betterhr.de">datenschutz@betterhr.de</a> an unseren verantwortlichen Mitarbeiter für den Datenschutz wenden.  </p>
					<p className="mt-2">Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren. Die für uns zuständige Datenschutzbehörde ist der / die:  </p>
					<p className="mt-2"><strong>Berliner Beauftragte(r) für Datenschutz und Informationsfreiheit
Friedrichstr. 219
10969 Berlin</strong>
					</p>
				</div>
				<div className="my-row mt-4">
					<h2>7. Änderungen unserer Datenschutzerklärung</h2>
				</div>
				<div className="my-row">
					<p>Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen. Für Ihren erneuten Besuch unserer Webseite oder Inanspruchnahme unserer Dienstleistungen gilt dann die insoweit aktuelle Datenschutzerklärung.</p>
				</div>
				<center className="mt-5 text-dark">Stand: 19/08/2019</center>
				<center className="mb-5 text-dark">Version 1.2</center>
			</div>
		</Layout>
	)
}
 
export default Datenschutz